import { Button, HTMLTable } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import React, { useMemo } from "react";
import { Post, Vote, VoteType } from "../generated";
import styles from "./PostsTable.module.scss";

interface PostsTableProps {
  posts: readonly Post[];
  votes: readonly Vote[];
  selectedPostId?: number;
  onRowClick: (posts: Post) => void;
  onDelete: (postId: number) => void;
  onVote: (postId: number, voteType: VoteType | undefined) => void;
}

const PostsTable: React.FC<PostsTableProps> = ({
  posts,
  votes,
  selectedPostId,
  onRowClick,
  onDelete,
  onVote,
}: PostsTableProps) => {
  const votesById = useMemo(() => {
    const byId: { [postId: string]: VoteType } = {};
    for (const vote of votes) {
      byId[vote.postId] = vote.voteType;
    }
    return byId;
  }, [votes]);

  return (
    <HTMLTable className={styles.table} bordered={true} interactive={true}>
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Content</th>
          <th>Upvotes</th>
          <th>Downvotes</th>
          <th>Delete</th>
          <th>Upvote</th>
          <th>Downvote</th>
          <th>Clear vote</th>
        </tr>
      </thead>
      <tbody>
        {posts?.map((post) => {
          const maybeVote = votesById[post.id];
          return (
            <tr
              onClick={() => onRowClick(post)}
              key={post.id}
              className={classnames({
                [styles.selected]: selectedPostId === post.id,
              })}
            >
              <td>{post.id}</td>
              <td>{post.title}</td>
              <td>{post.content}</td>
              <td
                className={classnames({
                  [styles.upvoted]: maybeVote === VoteType.Upvote,
                })}
              >
                {post.upvotes}
              </td>
              <td
                className={classnames({
                  [styles.downvoted]: maybeVote === VoteType.Downvote,
                })}
              >
                {post.downvotes}
              </td>
              <td>
                <div className={styles["table-button-cell-container"]}>
                  <Button
                    minimal={true}
                    icon={IconNames.TRASH}
                    onClick={() => onDelete(post.id)}
                  />
                </div>
              </td>
              <td>
                <div className={styles["table-button-cell-container"]}>
                  <Button
                    minimal={true}
                    onClick={() => onVote(post.id, VoteType.Upvote)}
                  >
                    👍
                  </Button>
                </div>
              </td>
              <td>
                <div className={styles["table-button-cell-container"]}>
                  <Button
                    minimal={true}
                    onClick={() => onVote(post.id, VoteType.Downvote)}
                  >
                    👎
                  </Button>
                </div>
              </td>
              <td>
                <div className={styles["table-button-cell-container"]}>
                  <Button
                    minimal={true}
                    onClick={() => onVote(post.id, undefined)}
                  >
                    ✊
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

export default PostsTable;
