import { H1 } from "@blueprintjs/core";
import LoginHeader from "../login/LoginHeader";
import logo from "../toupiao_128.svg";
import styles from "./AppHeader.module.scss";

function AppHeader() {
  return (
    <header className={styles.header}>
      <div className={styles["header-left"]}>
        <img className={styles.logo} src={logo} alt="logo" />
        <H1>Toupiao</H1>
      </div>
      <div className={styles["header-right"]}>
        <LoginHeader />
      </div>
    </header>
  );
}

export default AppHeader;
