import { useLogin } from "../hooks/useLogin";
import CurrentUsername from "../users/CurrentUsername";
import styles from "./LoginHeader.module.scss";

function LoginHeader() {
  const { isLoggedIn, login, logout } = useLogin();
  return (
    <div className={styles["login-header"]}>
      <div className={styles.description}>
        {isLoggedIn ? (
          <>
            Welcome back{" "}
            <b>
              <CurrentUsername />
            </b>
          </>
        ) : (
          "You are not logged in."
        )}
      </div>
      {isLoggedIn && <button onClick={logout}>Logout</button>}
      {!isLoggedIn && <button onClick={login}>Login</button>}
    </div>
  );
}

export default LoginHeader;
