import {
  Button,
  Card,
  FormGroup,
  InputGroup,
  TextArea,
} from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import styles from "./PostForm.module.scss";

interface PostFormProps {
  formTitle: string;
  onSubmit: (title: string, content: string) => void;
}

const PostForm: React.FC<PostFormProps> = ({
  onSubmit,
  formTitle,
}: PostFormProps) => {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const handleTitleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setTitle(event.currentTarget.value);
    },
    [setTitle]
  );
  const handleContentChange = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      setContent(event.currentTarget.value);
    },
    [setContent]
  );
  const handleSubmit = useCallback(() => {
    onSubmit(title, content);
    setTitle("");
    setContent("");
  }, [title, content, onSubmit]);

  return (
    <Card className={styles.form}>
      <div className={styles["form-title"]}>{formTitle}</div>
      <FormGroup label="Title" labelFor="title-input">
        <InputGroup
          id="title-input"
          value={title}
          onChange={handleTitleChange}
          placeholder="Post title..."
          fill={true}
        />
      </FormGroup>
      <FormGroup label="Content" labelFor="content-input">
        <TextArea
          id="content-input"
          value={content}
          onChange={handleContentChange}
          placeholder="Post content..."
          fill={true}
          growVertically={true}
        />
      </FormGroup>
      <Button onClick={handleSubmit}>Submit</Button>
    </Card>
  );
};

export default PostForm;
