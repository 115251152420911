import { KeycloakProvider } from "@react-keycloak/web";
import keycloak, { KEYCLOAK_INIT_OPTIONS } from "../keycloak/Keycloak";
import styles from "./App.module.scss";
import AppErrorBoundary from "./AppErrorBoundary";
import AppHeader from "./AppHeader";
import AppRouter from "./AppRouter";

function App() {
  return (
    <AppErrorBoundary>
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={KEYCLOAK_INIT_OPTIONS}
        LoadingComponent={<div>Loading...</div>}
      >
        <div className={styles.app}>
          <AppHeader />
          <AppRouter />
        </div>
      </KeycloakProvider>
    </AppErrorBoundary>
  );
}

export default App;
