import { Button, ButtonGroup, Card, H3 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { Comment } from "../generated";
import Username from "../users/Username";
import { DATE_TIME_FORMAT } from "../utils/dateTimeFormat";
import styles from "./CommentDetail.module.scss";
import UpdateCommentButton from "./UpdateCommentButton";

interface CommentDetailProps {
  comment: Comment;
  onUpdate: (newContent: string) => void;
  onDelete: () => void;
}

const CommentDetail: React.FC<CommentDetailProps> = ({
  comment,
  onUpdate,
  onDelete,
}: CommentDetailProps) => {
  return (
    <Card className={styles.comment}>
      <H3>Comment</H3>
      <ButtonGroup>
        <UpdateCommentButton onUpdateComment={onUpdate} />
        <Button icon={IconNames.TRASH} onClick={onDelete}>
          Delete
        </Button>
      </ButtonGroup>
      <div>
        <b>ID:</b> {comment.id}
      </div>
      <div>
        <b>Content:</b> {comment.content}
      </div>
      <div>
        <b>Created:</b> {DATE_TIME_FORMAT.format(comment.created.timestamp)} by{" "}
        <Username userId={comment.created.userId} />
      </div>
      <div>
        <b>Last updated:</b>{" "}
        {DATE_TIME_FORMAT.format(comment.lastUpdated.timestamp)} by{" "}
        <Username userId={comment.lastUpdated.userId} />
      </div>
    </Card>
  );
};

export default CommentDetail;
