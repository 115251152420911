/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Comment,
    CommentFromJSON,
    CommentToJSON,
    CreateCommentRequest,
    CreateCommentRequestFromJSON,
    CreateCommentRequestToJSON,
    CreatePostRequest,
    CreatePostRequestFromJSON,
    CreatePostRequestToJSON,
    Post,
    PostFromJSON,
    PostToJSON,
    UpdateCommentRequest,
    UpdateCommentRequestFromJSON,
    UpdateCommentRequestToJSON,
    UpdatePostRequest,
    UpdatePostRequestFromJSON,
    UpdatePostRequestToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    Vote,
    VoteFromJSON,
    VoteToJSON,
    VoteType,
    VoteTypeFromJSON,
    VoteTypeToJSON,
} from '../models';

export interface CreateCommentOperationRequest {
    createCommentRequest: CreateCommentRequest;
}

export interface CreatePostOperationRequest {
    createPostRequest: CreatePostRequest;
}

export interface DeleteCommentRequest {
    commentId: number;
}

export interface DeletePostRequest {
    postId: number;
}

export interface GetCommentRequest {
    commentId: number;
}

export interface GetPostRequest {
    postId: number;
}

export interface GetVoteRequest {
    postId: number;
}

export interface ListCommentsRequest {
    postId?: number;
    self?: boolean;
}

export interface ListPostsRequest {
    self?: boolean;
}

export interface ListUsersRequest {
    userIds: Array<string>;
}

export interface UnvotePostRequest {
    postId: number;
}

export interface UpdateCommentOperationRequest {
    commentId: number;
    updateCommentRequest: UpdateCommentRequest;
}

export interface UpdatePostOperationRequest {
    postId: number;
    updatePostRequest: UpdatePostRequest;
}

export interface VotePostRequest {
    postId: number;
    voteType: VoteType;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create a comment
     */
    async createCommentRaw(requestParameters: CreateCommentOperationRequest): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.createCommentRequest === null || requestParameters.createCommentRequest === undefined) {
            throw new runtime.RequiredError('createCommentRequest','Required parameter requestParameters.createCommentRequest was null or undefined when calling createComment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCommentRequestToJSON(requestParameters.createCommentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     * Create a comment
     */
    async createComment(requestParameters: CreateCommentOperationRequest): Promise<Comment> {
        const response = await this.createCommentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a post
     */
    async createPostRaw(requestParameters: CreatePostOperationRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.createPostRequest === null || requestParameters.createPostRequest === undefined) {
            throw new runtime.RequiredError('createPostRequest','Required parameter requestParameters.createPostRequest was null or undefined when calling createPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePostRequestToJSON(requestParameters.createPostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     * Create a post
     */
    async createPost(requestParameters: CreatePostOperationRequest): Promise<Post> {
        const response = await this.createPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an existing comment
     */
    async deleteCommentRaw(requestParameters: DeleteCommentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling deleteComment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing comment
     */
    async deleteComment(requestParameters: DeleteCommentRequest): Promise<void> {
        await this.deleteCommentRaw(requestParameters);
    }

    /**
     * Delete an existing post
     */
    async deletePostRaw(requestParameters: DeletePostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling deletePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing post
     */
    async deletePost(requestParameters: DeletePostRequest): Promise<void> {
        await this.deletePostRaw(requestParameters);
    }

    /**
     * Info for a specific comment
     */
    async getCommentRaw(requestParameters: GetCommentRequest): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling getComment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     * Info for a specific comment
     */
    async getComment(requestParameters: GetCommentRequest): Promise<Comment> {
        const response = await this.getCommentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get current user
     */
    async getCurrentUserRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get current user
     */
    async getCurrentUser(): Promise<User> {
        const response = await this.getCurrentUserRaw();
        return await response.value();
    }

    /**
     * Info for a specific post
     */
    async getPostRaw(requestParameters: GetPostRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling getPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     * Info for a specific post
     */
    async getPost(requestParameters: GetPostRequest): Promise<Post> {
        const response = await this.getPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Info for existing vote of the current user
     */
    async getVoteRaw(requestParameters: GetVoteRequest): Promise<runtime.ApiResponse<VoteType>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling getVote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoteTypeFromJSON(jsonValue));
    }

    /**
     * Info for existing vote of the current user
     */
    async getVote(requestParameters: GetVoteRequest): Promise<VoteType> {
        const response = await this.getVoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * List comments
     */
    async listCommentsRaw(requestParameters: ListCommentsRequest): Promise<runtime.ApiResponse<Array<Comment>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.postId !== undefined) {
            queryParameters['postId'] = requestParameters.postId;
        }

        if (requestParameters.self !== undefined) {
            queryParameters['self'] = requestParameters.self;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommentFromJSON));
    }

    /**
     * List comments
     */
    async listComments(requestParameters: ListCommentsRequest): Promise<Array<Comment>> {
        const response = await this.listCommentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List posts
     */
    async listPostsRaw(requestParameters: ListPostsRequest): Promise<runtime.ApiResponse<Array<Post>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.self !== undefined) {
            queryParameters['self'] = requestParameters.self;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostFromJSON));
    }

    /**
     * List posts
     */
    async listPosts(requestParameters: ListPostsRequest): Promise<Array<Post>> {
        const response = await this.listPostsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List users
     */
    async listUsersRaw(requestParameters: ListUsersRequest): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError('userIds','Required parameter requestParameters.userIds was null or undefined when calling listUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * List users
     */
    async listUsers(requestParameters: ListUsersRequest): Promise<Array<User>> {
        const response = await this.listUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all votes by the current user
     */
    async listVotesRaw(): Promise<runtime.ApiResponse<Array<Vote>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VoteFromJSON));
    }

    /**
     * Get all votes by the current user
     */
    async listVotes(): Promise<Array<Vote>> {
        const response = await this.listVotesRaw();
        return await response.value();
    }

    /**
     * Unset vote on a post
     */
    async unvotePostRaw(requestParameters: UnvotePostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling unvotePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unset vote on a post
     */
    async unvotePost(requestParameters: UnvotePostRequest): Promise<void> {
        await this.unvotePostRaw(requestParameters);
    }

    /**
     * Update an existing comment
     */
    async updateCommentRaw(requestParameters: UpdateCommentOperationRequest): Promise<runtime.ApiResponse<Comment>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling updateComment.');
        }

        if (requestParameters.updateCommentRequest === null || requestParameters.updateCommentRequest === undefined) {
            throw new runtime.RequiredError('updateCommentRequest','Required parameter requestParameters.updateCommentRequest was null or undefined when calling updateComment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCommentRequestToJSON(requestParameters.updateCommentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CommentFromJSON(jsonValue));
    }

    /**
     * Update an existing comment
     */
    async updateComment(requestParameters: UpdateCommentOperationRequest): Promise<Comment> {
        const response = await this.updateCommentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an existing post
     */
    async updatePostRaw(requestParameters: UpdatePostOperationRequest): Promise<runtime.ApiResponse<Post>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling updatePost.');
        }

        if (requestParameters.updatePostRequest === null || requestParameters.updatePostRequest === undefined) {
            throw new runtime.RequiredError('updatePostRequest','Required parameter requestParameters.updatePostRequest was null or undefined when calling updatePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePostRequestToJSON(requestParameters.updatePostRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFromJSON(jsonValue));
    }

    /**
     * Update an existing post
     */
    async updatePost(requestParameters: UpdatePostOperationRequest): Promise<Post> {
        const response = await this.updatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upvote or downvote a post
     */
    async votePostRaw(requestParameters: VotePostRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling votePost.');
        }

        if (requestParameters.voteType === null || requestParameters.voteType === undefined) {
            throw new runtime.RequiredError('voteType','Required parameter requestParameters.voteType was null or undefined when calling votePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.voteType !== undefined) {
            queryParameters['voteType'] = requestParameters.voteType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/votes/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upvote or downvote a post
     */
    async votePost(requestParameters: VotePostRequest): Promise<void> {
        await this.votePostRaw(requestParameters);
    }

}
