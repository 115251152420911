import { IconNames } from "@blueprintjs/icons";
import { flow } from "lodash-es";
import React from "react";
import ButtonWithDialog from "../common/ButtonWithDialog";
import CommentForm from "./CommentForm";

interface UpdateCommentButtonProps {
  onUpdateComment: (newContent: string) => void;
}

const UpdateCommentButton: React.FC<UpdateCommentButtonProps> = ({
  onUpdateComment,
}: UpdateCommentButtonProps) => {
  return (
    <ButtonWithDialog
      buttonIcon={IconNames.PLUS}
      buttonText="Update comment"
      dialogContent={(onClose) => (
        <CommentForm
          formTitle="Update comment"
          onSubmit={flow(onUpdateComment, onClose)}
        />
      )}
    />
  );
};

export default UpdateCommentButton;
