import { IconNames } from "@blueprintjs/icons";
import { flow } from "lodash-es";
import React from "react";
import ButtonWithDialog from "../common/ButtonWithDialog";
import PostForm from "./PostForm";

interface UpdatePostButtonProps {
  onUpdatePost: (newTitle: string, newContent: string) => void;
}

const UpdatePostButton: React.FC<UpdatePostButtonProps> = ({
  onUpdatePost,
}: UpdatePostButtonProps) => {
  return (
    <ButtonWithDialog
      buttonIcon={IconNames.PLUS}
      buttonText="Update post"
      dialogContent={(onClose) => (
        <PostForm
          formTitle="Update post"
          onSubmit={flow(onUpdatePost, onClose)}
        />
      )}
    />
  );
};

export default UpdatePostButton;
