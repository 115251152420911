import { useKeycloak } from "@react-keycloak/web";

interface UseLoginReturn {
  isLoggedIn: boolean;
  login(): void;
  logout(): void;
}

export function useLogin(): UseLoginReturn {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized || keycloak == null) {
    throw new Error("Keycloak not initialized yet.");
  }
  return {
    isLoggedIn: keycloak.authenticated ?? false,
    login: keycloak.login,
    logout: keycloak.logout,
  };
}
