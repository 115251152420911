/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePostRequest
 */
export interface CreatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequest
     */
    content: string;
}

export function CreatePostRequestFromJSON(json: any): CreatePostRequest {
    return CreatePostRequestFromJSONTyped(json, false);
}

export function CreatePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'content': json['content'],
    };
}

export function CreatePostRequestToJSON(value?: CreatePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'content': value.content,
    };
}


