/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attribution,
    AttributionFromJSON,
    AttributionFromJSONTyped,
    AttributionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    content: string;
    /**
     * 
     * @type {Attribution}
     * @memberof Post
     */
    created: Attribution;
    /**
     * 
     * @type {Attribution}
     * @memberof Post
     */
    lastUpdated: Attribution;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    upvotes?: number;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    downvotes?: number;
}

export function PostFromJSON(json: any): Post {
    return PostFromJSONTyped(json, false);
}

export function PostFromJSONTyped(json: any, ignoreDiscriminator: boolean): Post {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'content': json['content'],
        'created': AttributionFromJSON(json['created']),
        'lastUpdated': AttributionFromJSON(json['lastUpdated']),
        'upvotes': !exists(json, 'upvotes') ? undefined : json['upvotes'],
        'downvotes': !exists(json, 'downvotes') ? undefined : json['downvotes'],
    };
}

export function PostToJSON(value?: Post | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'content': value.content,
        'created': AttributionToJSON(value.created),
        'lastUpdated': AttributionToJSON(value.lastUpdated),
        'upvotes': value.upvotes,
        'downvotes': value.downvotes,
    };
}


