import { Button, Dialog, IconName } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import styles from "./ButtonWithDialog.module.scss";

interface ButtonWithDialogProps {
  buttonClassName?: string;
  buttonIcon?: IconName;
  buttonText: string;
  dialogContent: (onClose: () => void) => React.ReactNode;
}

const ButtonWithDialog: React.FC<ButtonWithDialogProps> = ({
  buttonClassName,
  buttonIcon,
  buttonText,
  dialogContent,
}: ButtonWithDialogProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = useCallback(() => setIsDialogOpen(true), []);
  const closeDialog = useCallback(() => setIsDialogOpen(false), []);

  return (
    <>
      <Button
        className={buttonClassName}
        icon={buttonIcon}
        onClick={openDialog}
      >
        {buttonText}
      </Button>
      <Dialog
        className={styles.dialog}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      >
        {dialogContent(closeDialog)}
      </Dialog>
    </>
  );
};

export default ButtonWithDialog;
