import { Button, ButtonGroup, H1, H2 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useCallback, useEffect, useState } from "react";
import CommentDetail from "../comments/CommentDetail";
import CreateCommentButton from "../comments/CreateCommentButton";
import {
  Comment,
  CreateCommentRequest,
  Post,
  UpdateCommentRequest,
} from "../generated";
import { useApiOrThrow } from "../hooks/useApi";
import Username from "../users/Username";
import { DATE_TIME_FORMAT } from "../utils/dateTimeFormat";
import UpdatePostButton from "./UpdatePostButton";

interface PostDetailProps {
  post: Post;
  onUpdate: (newTitle: string, newContent: string) => void;
  onDelete: () => void;
}

const PostDetail: React.FC<PostDetailProps> = ({
  post,
  onUpdate,
  onDelete,
}: PostDetailProps) => {
  const api = useApiOrThrow();
  const [comments, setComments] = useState<readonly Comment[] | undefined>();

  const reloadComments = useCallback(async () => {
    try {
      setComments(await api.listComments({ postId: post.id }));
    } catch (e) {
      console.error("Failed to reload comments", e);
      setComments([]);
    }
  }, [api, post]);

  const reloadData = useCallback(() => {
    reloadComments();
  }, [reloadComments]);

  useEffect(() => {
    reloadComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  const createComment = useCallback(
    async (createCommentRequest: CreateCommentRequest) => {
      await api.createComment({
        createCommentRequest,
      });
      reloadData();
    },
    [api, reloadData]
  );
  const createCommentFormSubmit = useCallback(
    (content: string) => {
      if (content.length === 0) {
        return;
      }
      createComment({ postId: post.id, content });
    },
    [post, createComment]
  );

  const updateComment = useCallback(
    async (commentId: number, updateCommentRequest: UpdateCommentRequest) => {
      await api.updateComment({
        commentId,
        updateCommentRequest,
      });
      reloadData();
    },
    [api, reloadData]
  );

  const deleteComment = useCallback(
    async (commentId: number) => {
      await api.deleteComment({ commentId });
      reloadData();
    },
    [api, reloadData]
  );

  return (
    <div>
      <H1>Post</H1>
      <ButtonGroup>
        <UpdatePostButton onUpdatePost={onUpdate} />
        <Button icon={IconNames.TRASH} onClick={onDelete}>
          Delete
        </Button>
      </ButtonGroup>
      <div>
        <b>ID:</b> {post.id}
      </div>
      <div>
        <b>Title:</b> {post.title}
      </div>
      <div>
        <b>Content:</b> {post.content}
      </div>
      <div>
        <b>Upvotes:</b> {post.upvotes}
      </div>
      <div>
        <b>Downvotes:</b> {post.downvotes}
      </div>
      <div>
        <b>Created:</b> {DATE_TIME_FORMAT.format(post.created.timestamp)} by{" "}
        <Username userId={post.created.userId} />
      </div>
      <div>
        <b>Last updated:</b>{" "}
        {DATE_TIME_FORMAT.format(post.lastUpdated.timestamp)} by{" "}
        <Username userId={post.lastUpdated.userId} />
      </div>
      <H2>Comments</H2>
      <CreateCommentButton onCreateComment={createCommentFormSubmit} />
      {comments?.map((comment) => {
        const updateCommentFormSubmit = (newContent: string) => {
          if (newContent.length === 0) {
            return;
          }
          updateComment(comment.id, { newContent });
        };
        return (
          <CommentDetail
            key={comment.id}
            comment={comment}
            onUpdate={updateCommentFormSubmit}
            onDelete={() => deleteComment(comment.id)}
          />
        );
      })}
    </div>
  );
};

export default PostDetail;
