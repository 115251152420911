import { IconNames } from "@blueprintjs/icons";
import { flow } from "lodash-es";
import React from "react";
import ButtonWithDialog from "../common/ButtonWithDialog";
import CommentForm from "./CommentForm";

interface CreateCommentButtonProps {
  onCreateComment: (content: string) => void;
}

const CreateCommentButton: React.FC<CreateCommentButtonProps> = ({
  onCreateComment,
}: CreateCommentButtonProps) => {
  return (
    <ButtonWithDialog
      buttonIcon={IconNames.PLUS}
      buttonText="Create comment"
      dialogContent={(onClose) => (
        <CommentForm
          formTitle="Create comment"
          onSubmit={flow(onCreateComment, onClose)}
        />
      )}
    />
  );
};

export default CreateCommentButton;
