import { Button, ButtonGroup, H1 } from "@blueprintjs/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CreatePostRequest,
  Post,
  UpdatePostRequest,
  Vote,
  VoteType,
} from "../generated";
import { useApiOrThrow } from "../hooks/useApi";
import Page from "../page/Page";
import CreatePostButton from "./CreatePostButton";
import PostDetail from "./PostDetail";
import PostsTable from "./PostsTable";

function PostsPage() {
  const api = useApiOrThrow();
  const [posts, setPosts] = useState<readonly Post[] | undefined>();
  const [votes, setVotes] = useState<readonly Vote[] | undefined>();

  const [selectedPostId, setSelectedPostId] = useState<number | undefined>();
  const selectedPost = useMemo(
    () => posts?.find((post) => post.id === selectedPostId),
    [selectedPostId, posts]
  );

  const reloadPosts = useCallback(async () => {
    try {
      setPosts(await api.listPosts({}));
    } catch (e) {
      console.error("Failed to reload posts", e);
      setPosts([]);
    }
  }, [api]);

  const reloadVotes = useCallback(async () => {
    try {
      setVotes(await api.listVotes());
    } catch (e) {
      console.error("Failed to reload votes", e);
      setVotes([]);
    }
  }, [api]);

  const reloadData = useCallback(() => {
    reloadPosts();
    reloadVotes();
  }, [reloadPosts, reloadVotes]);

  useEffect(() => {
    reloadData();
    // on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPost = useCallback(
    async (createPostRequest: CreatePostRequest) => {
      await api.createPost({
        createPostRequest,
      });
      reloadData();
    },
    [api, reloadData]
  );
  const createPostFormSubmit = useCallback(
    (title: string, content: string) => {
      if (title.length === 0 || content.length === 0) {
        return;
      }
      createPost({ title, content });
    },
    [createPost]
  );

  const updatePost = useCallback(
    async (postId: number, updatePostRequest: UpdatePostRequest) => {
      await api.updatePost({
        postId,
        updatePostRequest,
      });
      reloadData();
    },
    [api, reloadData]
  );
  const updatePostFormSubmit = useCallback(
    (newTitle: string, newContent: string) => {
      if (
        selectedPost == null ||
        (newTitle.length === 0 && newContent.length === 0)
      ) {
        return;
      }
      updatePost(selectedPost?.id, {
        newTitle: newTitle.length > 0 ? newTitle : undefined,
        newContent: newContent.length > 0 ? newContent : undefined,
      });
    },
    [selectedPost, updatePost]
  );

  const deletePost = useCallback(
    async (postId: number) => {
      await api.deletePost({ postId });
      reloadData();
    },
    [api, reloadData]
  );

  const votePost = useCallback(
    async (postId: number, voteType: VoteType | undefined) => {
      if (voteType == null) {
        await api.unvotePost({ postId });
      } else {
        await api.votePost({ postId, voteType });
      }
      reloadData();
    },
    [api, reloadData]
  );

  return (
    <Page>
      <H1>Posts page</H1>
      <ButtonGroup>
        <CreatePostButton onCreatePost={createPostFormSubmit} />
        {selectedPost !== undefined && (
          <Button onClick={() => setSelectedPostId(undefined)}>
            Unselect post
          </Button>
        )}
      </ButtonGroup>
      <PostsTable
        posts={posts ?? []}
        votes={votes ?? []}
        selectedPostId={selectedPostId}
        onRowClick={(post) => setSelectedPostId(post.id)}
        onDelete={deletePost}
        onVote={votePost}
      />
      {selectedPost !== undefined && (
        <PostDetail
          post={selectedPost}
          onUpdate={updatePostFormSubmit}
          onDelete={() => deletePost(selectedPost.id)}
        />
      )}
    </Page>
  );
}

export default PostsPage;
