/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VoteType {
    Upvote = 'upvote',
    Downvote = 'downvote'
}

export function VoteTypeFromJSON(json: any): VoteType {
    return VoteTypeFromJSONTyped(json, false);
}

export function VoteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteType {
    return json as VoteType;
}

export function VoteTypeToJSON(value?: VoteType | null): any {
    return value as any;
}

