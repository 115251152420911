/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attribution,
    AttributionFromJSON,
    AttributionFromJSONTyped,
    AttributionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    postId: number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    content: string;
    /**
     * 
     * @type {Attribution}
     * @memberof Comment
     */
    created: Attribution;
    /**
     * 
     * @type {Attribution}
     * @memberof Comment
     */
    lastUpdated: Attribution;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'postId': json['postId'],
        'content': json['content'],
        'created': AttributionFromJSON(json['created']),
        'lastUpdated': AttributionFromJSON(json['lastUpdated']),
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'postId': value.postId,
        'content': value.content,
        'created': AttributionToJSON(value.created),
        'lastUpdated': AttributionToJSON(value.lastUpdated),
    };
}


