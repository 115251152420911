import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import DEVELOPMENT_PROXY_URL from "../developmentProxyUrl";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak: Keycloak.KeycloakInstance = Keycloak({
  url:
    // In development mode we point keycloak requests to the configured
    // development backend otherwise use a relative path for production
    // as keycloak should be available on the same base path on the frontdoor.
    process.env.NODE_ENV === "development"
      ? `${DEVELOPMENT_PROXY_URL}/auth`
      : "/auth",
  realm: "toupiao",
  clientId: "toupiao-app",
});

export const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = {
  enableLogging: true,
  onLoad: "check-sso",
  // Modern browsers can have problems with iframe for check login and
  // silent sso. Was seeing behaviour with not auto-logging in properly and
  // auto logging off after 5s from iframe not working correctly.
  // https://www.keycloak.org/docs/latest/securing_apps/#_modern_browsers
  checkLoginIframe: false,
};

export default keycloak;
