import { useEffect, useState } from "react";
import { User } from "../generated";
import { useApiOrThrow } from "./useApi";

let currentUser: User | undefined;

export function useCurrentUser(): User | undefined {
  const api = useApiOrThrow();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (currentUser != null) {
      setUser(currentUser);
      return;
    }

    async function loadCurrentUser() {
      const response = await api.getCurrentUser();
      currentUser = response;
      setUser(response);
    }
    loadCurrentUser();
  }, [api]);

  return user;
}
