import { IconNames } from "@blueprintjs/icons";
import { flow } from "lodash-es";
import React from "react";
import ButtonWithDialog from "../common/ButtonWithDialog";
import PostForm from "./PostForm";

interface CreatePostButtonProps {
  onCreatePost: (title: string, content: string) => void;
}

const CreatePostButton: React.FC<CreatePostButtonProps> = ({
  onCreatePost,
}: CreatePostButtonProps) => {
  return (
    <ButtonWithDialog
      buttonIcon={IconNames.PLUS}
      buttonText="Create post"
      dialogContent={(onClose) => (
        <PostForm
          formTitle="Create post"
          onSubmit={flow(onCreatePost, onClose)}
        />
      )}
    />
  );
};

export default CreatePostButton;
