/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCommentRequest
 */
export interface UpdateCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentRequest
     */
    newContent?: string;
}

export function UpdateCommentRequestFromJSON(json: any): UpdateCommentRequest {
    return UpdateCommentRequestFromJSONTyped(json, false);
}

export function UpdateCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCommentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newContent': !exists(json, 'newContent') ? undefined : json['newContent'],
    };
}

export function UpdateCommentRequestToJSON(value?: UpdateCommentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newContent': value.newContent,
    };
}


