/* tslint:disable */
/* eslint-disable */
/**
 * Toupiao
 * Toupiao OpenAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VoteType,
    VoteTypeFromJSON,
    VoteTypeFromJSONTyped,
    VoteTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Vote
 */
export interface Vote {
    /**
     * 
     * @type {number}
     * @memberof Vote
     */
    postId: number;
    /**
     * 
     * @type {VoteType}
     * @memberof Vote
     */
    voteType: VoteType;
}

export function VoteFromJSON(json: any): Vote {
    return VoteFromJSONTyped(json, false);
}

export function VoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postId': json['postId'],
        'voteType': VoteTypeFromJSON(json['voteType']),
    };
}

export function VoteToJSON(value?: Vote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postId': value.postId,
        'voteType': VoteTypeToJSON(value.voteType),
    };
}


