import { useLogin } from "../hooks/useLogin";
import PostsPage from "../posts/PostsPage";
import WelcomePage from "../welcome/WelcomePage";

function AppRouter() {
  const { isLoggedIn } = useLogin();
  return isLoggedIn ? <PostsPage /> : <WelcomePage />;
}

export default AppRouter;
