import { useEffect, useState } from "react";
import { User } from "../generated";
import { useApiOrThrow } from "./useApi";

const users: { [userId: string]: User } = {};

export function useUser(userId: string): User | undefined {
  const api = useApiOrThrow();
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const maybeUser = users[userId];
    if (maybeUser != null) {
      setUser(maybeUser);
      return;
    }

    async function loadUser() {
      const response = await api.listUsers({ userIds: [userId] });
      if (response.length > 0) {
        const user = response[0];
        users[userId] = user;
        setUser(user);
      }
    }
    loadUser();
  }, [userId, api]);

  return user;
}
