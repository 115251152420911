import React from "react";
import { useUser } from "../hooks/useUser";

interface UsernameProps {
  userId: string;
}

const Username: React.FC<UsernameProps> = ({ userId }: UsernameProps) => {
  const user = useUser(userId);
  return user != null ? <>{user.username}</> : null;
};

export default Username;
