import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { BASE_PATH, Configuration, DefaultApi } from "../generated";

export function useApi(): DefaultApi | undefined {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;
  const api = useMemo(() => {
    if (token == null) {
      return undefined;
    }
    return new DefaultApi(
      new Configuration({
        basePath: new URL(BASE_PATH).pathname, // Use relative path which will be proxied in development
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }, [token]);
  return api;
}

export function useApiOrThrow(): DefaultApi {
  const api = useApi();
  if (api == null) {
    throw new Error("Token missing or invalid.");
  }
  return api;
}
