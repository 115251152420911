import { H1 } from "@blueprintjs/core";
import Page from "../page/Page";

function WelcomePage() {
  return (
    <Page>
      <H1>Welcome</H1>
      <div>Toupiao - create and vote on posts</div>
    </Page>
  );
}

export default WelcomePage;
