import { Button, Card, FormGroup, TextArea } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import styles from "./CommentForm.module.scss";

interface CommentFormProps {
  formTitle: string;
  onSubmit: (content: string) => void;
}

const CommentForm: React.FC<CommentFormProps> = ({
  onSubmit,
  formTitle,
}: CommentFormProps) => {
  const [content, setContent] = useState<string>("");

  const handleContentChange = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      setContent(event.currentTarget.value);
    },
    [setContent]
  );
  const handleSubmit = useCallback(() => {
    onSubmit(content);
    setContent("");
  }, [content, onSubmit]);

  return (
    <Card className={styles.form}>
      <div className={styles["form-title"]}>{formTitle}</div>
      <FormGroup label="Content" labelFor="content-input">
        <TextArea
          id="content-input"
          value={content}
          onChange={handleContentChange}
          placeholder="Comment content..."
          fill={true}
          growVertically={true}
        />
      </FormGroup>
      <Button onClick={handleSubmit}>Submit</Button>
    </Card>
  );
};

export default CommentForm;
